<template>
  <div id="categories">
    <MarqueeComponent v-if="showMarquee" :from-main="false" :bg-color="'#000000'" :font-color="'#ffffff'"/>

    <Loading v-if="loading" />

    <section class="site-section" v-else>
      <div class="container-fluid">
        <div class="section-header">
          <h2 class="section-title mb-3" v-text="subCategory.titleHEB" />
        </div>

        <div class="section-body" ref="sectionBody">
          <div class="products-container">
            <div class="row mb-3" v-if="bannerImg">
              <div class="col-12 col-md-9 offset-md-1">
                <div class="banner-photo">
                  <img :src="bannerImg" alt="" />
                </div>
              </div>
            </div>

            <div class="row carts-row">
              <div :class="['col-12', 'col-md-9', {'offset-md-1': !showFilter}]">
                <div class="alert alert-warning" v-if="productsIsEmpty" v-text="'לא נמצאו מוצרים'" />

                <div id="infinite-list" class="row row-cols-2 row-cols-md-3 row-cols-lg-3 list-products" v-else>
                  <div
                    v-for="(product, index) of products"
                    :key="index"
                    :class="productTileClass(product)"
                  >
                    <div v-if="product.type === 'banner'" class="mb-3">
                      <img :src="product.banner" alt="" />
                    </div>

                    <CardProduct :product="product" :info="true" v-else />
                  </div>
                </div>
                <div id="loading" v-if="loadingMore">
                  <div class="spinner-border gong-spinner" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>

              <div :class="['col-12', {'col-md-3': showFilter, 'col-md-2': !showFilter}]">
                <FilterComponent />
              </div>
            </div>
          </div>
        </div>

        <!--
        <div class="section-footer" v-if="products.length">
          <b-pagination-nav
            size="sm"
            :link-gen="linkGen"
            :number-of-pages="pagesProducts"
            :limit="pagesProducts"
            use-router
            first-number
            last-number
          />
        </div>
        -->
      </div>
    </section>
  </div>
</template>

<script>
import device from 'current-device'
import { eventEmitter } from '@/main'
import MarqueeComponent from '@/components/MarqueeComponent'
import CardProduct from '@/components/CardProduct'
import FilterComponent from '@/components/categories/FilterComponent'

export default {
  name: 'Categories',

  components: {
    MarqueeComponent, CardProduct, FilterComponent,
  },

  props: ['subCategoryId'],

  metaInfo() {
    return {
      title: this.seoProducts.title !== '' ? this.seoProducts.title??'' : process.env.VUE_APP_NAME+' | '+this.subCategory.titleHEB+' | '+process.env.VUE_APP_NAME_HEB,
      meta: [
        { name: 'keywords', content: this.seoProducts.keywords },
        { name: 'description', content: this.seoProducts.description },
      ],
    }
  },

  data() {
    return {
      device,
      loading: false,
      loadingMore: false,
      productsIsEmpty: false,
      showFilter: false,
      currentPage: 1

    }
  },

  computed: {
    seoProducts() {
      return this.$store.getters.seoProducts
    },
    globalSettings() {
      return this.$store.getters.globalSettings
    },
    color() {
      return this.$store.getters.color
    },
    price() {
      return this.$store.getters.price
    },
    subCategory() {
      return this.$store.getters.subCategory
    },
    bannerImg() {
      const banner = this.$store.getters.banner
      if(banner.location !== undefined) {
        return device.desktop()
          ? process.env.VUE_APP_IMAGE_URL+'/'+banner.desk
          : process.env.VUE_APP_IMAGE_URL+'/'+banner.mobile
      }

      return null
    },
    pagesProducts() {
      return this.$store.getters.pagesProducts
    },
    products() {
      return this.$store.getters.products
    },
    filteredProducts() {
      return this.$store.getters.filteredProducts
    },
    scrollPosition() {
      return this.$store.getters.scrollPosition
    },

    showMarquee() {
      return parseInt(this.subCategoryId) !== 28
        && !this.productsIsEmpty
        && this.globalSettings.length
        && parseInt(this.subCategoryId) !== 45
        && parseInt(this.subCategoryId) !== 65
        && parseInt(this.subCategoryId) !== 93
    }
  },

  watch: {
    async $route() {
      this.$store.commit('setScrollPosition', null)
      await this.loadData()
    },

    color(newColor) {
      this.showFilter = false
      const push = {name: 'subCategories', params: {subCategoryId: this.subCategory.id}}
      if(newColor) push.query = {colorId: newColor.id}
      this.$router.push(push)
    },

    price(newPrice) {
      let filteredProducts = newPrice
        ? this.filteredProducts.filter(product => product.type !== 'banner').filter(product => product.webPriceHEB >= newPrice[0] && product.webPriceHEB <= newPrice[1])
        : this.filteredProducts

      if(this.color)
        filteredProducts = filteredProducts.filter(product => product.colors.findIndex(c => c.id === this.color.id) !== -1)

      this.$store.commit('setProducts', filteredProducts)
      this.productsIsEmpty = filteredProducts.length === 0
    }
  },

  methods: {
    
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },

    productTileClass(product){

        if(product.type == 'banner')
          return 'col-12 col-md-12 col-lg-12'

        if(product.visual_block == 'double_pic' || product.visual_block == 'pic_and_vid')
            return 'col-12 col-md-12 col-lg-6'

        return 'col-6 col-md-3 col-lg-3'; // default
    },

    async loadData() {
      this.loading = true
      this.$store.commit('setBanner', {})
      if(this.$route.params.fromMenu) { // if came from menu, clear state of color
        this.$store.commit('setColor', null)
      }

      await this.$store.dispatch('getColors', this.subCategoryId)
      await this.$store.dispatch('getSubCategory', { subCategoryId: this.subCategoryId })
      await this.$store.dispatch('getProducts', {
        subCategoryId: this.subCategoryId,
        page: this.$route.query.page,
        device: device.mobile() ? 'mobile' : 'desktop',
        colorId: this.color ? this.color.id : this.$route.query.colorId
      })

      this.productsIsEmpty = this.products.length === 0
      this.loading = false
    },
    async loadMore() {
      
      if(this.loadingMore) return ;

      if(this.currentPage >= this.$store.getters.pagesProducts){
        console.log('no more pages');
        return;
      }
     
      this.loadingMore = true
      try{
      await this.$store.dispatch('getProducts', {
        subCategoryId: this.subCategoryId,
        page: ++this.currentPage,
        device: device.mobile() ? 'mobile' : 'desktop',
        colorId: this.color ? this.color.id : this.$route.query.colorId
      }).then(()=> {
         this.loadingMore = false;
         console.log(this.currentPage+ ' page loaded');
      })
      }catch(e){
        this.loadingMore = false;
      }

      this.productsIsEmpty = this.products.length === 0
     
    },
  },

  async created() {
    await this.$store.dispatch('getGlobalSettings')

    if(this.products.length === 0 || (parseInt(this.subCategoryId) !== this.subCategory.id))
      await this.loadData()

    eventEmitter.$on('onShowFilter', value => this.showFilter = value)

    this.$router.options.scrollBehavior = async (to, from, savedPosition) => {
      if(this.scrollPosition && this.$route.name === 'subCategories') {
        if(this.$route.params.scrollTop) {
          return {x: 0, y: 0}
        } else {
          return new Promise((resolve, reject) => setTimeout(() => resolve(this.scrollPosition), 800))
        }
      } else {
        const position = savedPosition || {x: 0, y: 0}
        return new Promise((resolve, reject) => setTimeout(() => resolve(position), 800))
      }
    }


     window.onscroll = () => {
        const infiniteList = document.getElementById('infinite-list');
        if(!infiniteList) return;
        let rect = infiniteList.getBoundingClientRect();
        if (document.documentElement.scrollTop + window.innerHeight > rect.height) {
            this.loadMore();
        }
      }

  }
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/_functions.scss';
@import '~bootstrap/scss/_variables.scss';
@import '~bootstrap/scss/mixins/_breakpoints.scss';
#loading{
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 5rem 0;
}
.gong-spinner{
  width: 4rem;
  height: 4rem;
}
.new-range{
  width: 200px;
  text-align: center;
  position: relative;
}
.new-range .my-range-input {
  position: absolute;
  left: 0;
  bottom: 0;
}
.my-range-input::-webkit-slider-thumb{
  z-index: 2;
  position: relative;
  top: 2px;
  margin-top: -7px;
}
.my-range{
  position: relative;
  width: 300px;
}
.range-left{
  position: absolute;
  left: 0;
  top: -2px;
  width: .5rem;
  height: .5rem;
  padding: 0;
  background-color: #000;
  border-radius: 50%;
  border: none;
  z-index: 2;
}
.range-right{
  position: absolute;
  right: 0;
  top: -2px;
  width: .5rem;
  height: .5rem;
  padding: 0;
  background-color: #000;
  border-radius: 50%;
  border: none;
  z-index: 2;
}
.process{
  position: absolute;
  left: 0;
  right: 0;
  height: .15rem;
  background-color: #d7d7d7;
  z-index: 1;
}
#categories .container-fluid{
  padding-left: 6.8rem;
  padding-right: 2rem;
}
#categories .site-section{
  margin-top: 15px;
}
.sorting{
  margin-bottom: 0.8rem;
}
.products-container > .row > .offset-md-1 {
  margin-left: 7.8%;
}
.products-container > .row > .col-md-9{
  flex: 0 0 81.3%;
  max-width: 81.3%;
}
.products-container > .row > .col-md-2 {
  flex: 0 0 10.766667%;
  max-width: 10.766667%;
}
.products-container > .row > .col-md-3 {
  flex: 0 0 18.7%;
  max-width: 18.7%;
}
.products-container .col-md-9 .row .col-md-3{
  padding-left: 12.5px;
  padding-right: 12.5px;
}
.list-products{
  direction: rtl;
}
.alert{
  text-align: center;
  margin-top: 3rem;
}
.section-footer{
  display: flex;
  justify-content: center;
}
.banner-photo{
  margin-left: -3px;
  margin-right: -2px;
}

@include media-breakpoint-down(sm) {
  #categories .site-section{
    margin-top: 1.4rem;
  }
  #innerLayout .section-header{
    margin-bottom: .9rem;
  }
  #innerLayout .section-title{
    font-size: 1.3rem;
  }
  #categories .container-fluid{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  #categories .row{
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  #categories .col-6{
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .products-container{
    display: flex;
    flex-direction: column;
  }
  .products-container > .row{
    flex-direction: column-reverse;
  }
  .products-container > .row > .col-12{
    flex: 0 0 100%;
    max-width: 100%;
  }
  .products-container > .row > .offset-md-1{
    margin-left: 0;
  }
  .products-container .col-md-3{
    margin-bottom: 0;
  }
}
</style>
